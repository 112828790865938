var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        padding: "30px",
        height: "100%",
        "background-color": "white",
        display: "flex",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "24px",
            height: "40px",
            "margin-top": "10px",
            "line-height": "40px",
          },
        },
        [_vm._v(" " + _vm._s(_vm.shareConfig.remark) + " ")]
      ),
      _vm.shareConfig.id
        ? _c(
            "div",
            {
              staticStyle: {
                height: "100%",
                display: "flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "100%",
                    display: "inline-block",
                    "flex-grow": "1",
                    "flex-shrink": "1",
                  },
                },
                [
                  _c("MyVxeTable", {
                    ref: "gridTable",
                    attrs: {
                      columns: _vm.gridOptions.columns,
                      data: _vm.gridOptions.data,
                      params: _vm.gridOptions.params,
                      pageConfig: _vm.gridOptions.pagerConfig,
                      selectAllRowsHandler: _vm.GetAllDataRows,
                    },
                    on: {
                      "page-change": _vm.handleDataPageChange,
                      "view-picture": _vm.onHandlePreviewImg,
                      "filter-change": _vm.gridFilterChanged,
                      "sort-change": _vm.gridSortChanged,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "searchContainer",
                          fn: function () {
                            return [
                              _c(
                                "el-form",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { inline: true },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-bottom": "0" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "prefix-icon": "el-icon-search",
                                          placeholder: "全局搜索...",
                                          size: "mini",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.dataQueryParams.fillValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataQueryParams,
                                              "fillValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataQueryParams.fillValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-bottom": "0" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            icon: "el-icon-search",
                                            size: "mini",
                                          },
                                          on: {
                                            click: _vm.onHandleQueryDataClick,
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-refresh",
                                            size: "mini",
                                          },
                                          on: {
                                            click: _vm.onHandleResetDataClick,
                                          },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "status_text",
                          fn: function () {
                            return undefined
                          },
                          proxy: true,
                        },
                        {
                          key: "operate",
                          fn: function () {
                            return [
                              _c("vxe-column", {
                                attrs: {
                                  title: "操作",
                                  width: "90",
                                  "slots:default": "operate",
                                  fixed: "right",
                                  "header-class-name": "sysfield",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                status: "primary",
                                                icon: "el-icon-view",
                                                content: "查看",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onEditFillClick(
                                                    row["fillId"],
                                                    1,
                                                    "MANAGE"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3274849208
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3398671504
                    ),
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("VFormViewer", {
        ref: "dlgvFormViewer",
        on: { "on-save": _vm.onHandleEditRefreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }