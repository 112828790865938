// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1647072697356");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1647072697356");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1647072697356");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"iconfont-jyd\"; /* Project id 3225451 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n.iconfont-jyd {\n  font-family: \"iconfont-jyd\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.jyd-icon-paixu:before {\n  content: \"\\eaf0\";\n}\n.jyd-icon-sort-ascending:before {\n  content: \"\\eaf3\";\n}\n.jyd-icon-sort-descending:before {\n  content: \"\\eaf4\";\n}\n\n", ""]);
// Exports
module.exports = exports;
