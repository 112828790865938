<template>
  <div style="padding:30px;height:100%;background-color:white;display:flex;flex-direction:column;">
    <div style="text-align:center;font-size:24px;height:40px;margin-top:10px;line-height:40px;">
      {{shareConfig.remark}}
    </div>
    <div v-if="shareConfig.id" style="height: 100%;display: flex; flex-direction: column;">
      <div style="height: 100%;display: inline-block;flex-grow:1;flex-shrink: 1;">
        <MyVxeTable ref="gridTable" :columns="gridOptions.columns" :data="gridOptions.data" :params="gridOptions.params" :pageConfig="gridOptions.pagerConfig" :selectAllRowsHandler="GetAllDataRows" @page-change="handleDataPageChange" @view-picture="onHandlePreviewImg" @filter-change="gridFilterChanged" @sort-change="gridSortChanged">
          <template v-slot:searchContainer>
            <el-form :inline="true" style="width:100%">
              <el-form-item style="margin-bottom: 0;">
                <el-input v-model="dataQueryParams.fillValue" prefix-icon="el-icon-search" placeholder="全局搜索..." size="mini" clearable>
                </el-input>
              </el-form-item>
              <el-form-item style="margin-bottom: 0;">
                <el-button type="success" icon="el-icon-search" size="mini" @click="onHandleQueryDataClick">查询</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="onHandleResetDataClick">重置</el-button>
              </el-form-item>
            </el-form>
            <!-- </div> -->
          </template>
          <template v-slot:status_text>
            <!-- <vxe-column title="当前状态" width="150" header-class-name="sysfield" :filters="[{ data: []}]" :filter-render="{name:'filter-status'}" :params="filters.status" v-if="formAuth.currentQueryReviewListAuth"> -->
            <!-- <vxe-column title="当前状态" width="150" field="status" header-class-name="sysfield" :filters="[{data:{}}]"  :filter-render="{name:'filter-status'}" :params="filters.status" v-if="formAuth.currentQueryReviewListAuth">
                <template #default="{ row }">
                  <StatusCode :statusCode="row.status" :fillStatusCode="row.fillStatus"></StatusCode>
                </template>
              </vxe-column> -->
          </template>
          <template v-slot:operate>
            <vxe-column title="操作" width="90" slots:default='operate' fixed="right" header-class-name="sysfield" align="center">
              <template #default="{ row }">
                <el-button type="text" status="primary" icon="el-icon-view" content="查看" @click="onEditFillClick(row['fillId'], 1,'MANAGE')">查看</el-button>
              </template>
            </vxe-column>
          </template>
        </MyVxeTable>
      </div>
    </div>
    <VFormViewer ref="dlgvFormViewer" @on-save="onHandleEditRefreshData"></VFormViewer>
  </div>
</template>
<script>
  import moment from "moment";
  import MyVxeTable from "@/views/system/form/components/tables/table.vue";
  import StatusCode from "@/views/system/form/components/statusCode.vue";
  import VFormViewer from "./components/vFormViewer/index.vue";
  import gridConfig from "@/utils/grid.js";

  import { aliUploader } from "@/utils/oss";
  import { getSessionStorageObj } from "@/utils/db";
  import {
    beforeBackConfirm,
    showPicturePreview,
    changedValue,
  } from "@/utils/formUtil";
  import { deepClone } from "@/utils/deepClone.js";

  import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
  import {
    formDetail,
    fillDataDetail,
    fillDetail,
    httpGetFormFieldV2,
    deleleteFile,
  } from "@/api/system/form";

  import {
    getDataShare,
    getDataShareRequiredPassword,
    dataShareCheckPassword,
  } from "@/api/system/form.js";
  export default {
    components: {
      MyVxeTable,
      StatusCode,
      VFormViewer,
    },
    data() {
      return {
        moment: moment,
        shareId: 0,
        shareConfig: {},
        formJson: {},
        formDetail: [],
        // 数据查看表格插件
        gridOptions: {
          border: true,
          align: "center",
          resizable: true,
          keepSource: true,
          showOverflow: true,
          loading: false,
          columns: [],
          data: [],
          tableToolbar: {
            custom: true,
          },
          params: {
            selectedRowKeys: [],
          },
          pagerConfig: {
            total: 0,
            currentPage: 1,
            pageSize: 20,
            pageSizes: [20, 50, 100],
          },
        },
        dataQueryParams: {
          formFieldId: "",
          fillValue: "",
          queryStatus: "",
          payStatus: "",
          fillStartTime: "",
          fillEndTime: "",
        },
      };
    },
    created() {
      this.shareId = this.$route.params.id;

      getDataShareRequiredPassword(this.shareId).then((data) => {
        if (data.code === 200) {
          if (data.data) {
            this.showPasswordPrompt();
          } else {
            this.loadDataShare();
          }
          this.initVFormViewerOptionData();
        }
      });
    },
    methods: {
      initVFormViewerOptionData() {
        const userToken = this.cookie.get("User-Token");
        const sessionStorageUser = getSessionStorageObj("users");
        try {
          this.optionData = {
            userToken: userToken,
            apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
            formId: this.decode(this.$route.params.id),
            formPayInfo: this.getFormPayInfo,
          };
        } catch {
          this.optionData = {
            userToken: userToken,
            apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
            formPayInfo: this.getFormPayInfo,
          };
        }

        //图片选项、关联选择中使用的渲染器等数据
        this.optionData.formDataSelectHandler = this.showDataSelect;
        this.optionData.formDataViewHandler = this.showDataView;
        this.optionData.formPicturePreviewHandler = showPicturePreview;
        this.optionData.formDataCardRender = this.dataCardRender();
        this.optionData.formAgencyId = this.formAgencyId;
        this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
        this.optionData.loadAgencyUserListHandler = agencyUserList;
        this.optionData.loadAgencyUserHandler = getAgencyUser;
        this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;
        this.optionData.formData = {};

        // this.optionData.aliOSSUpload =aliUploader(true,
        //  {  //OK
        //   formId:'formId',
        //   agencyId:'agencyId',
        //   userId:sessionStorageUser.userId||'userId',
        //   uploadPath:'fillFile/{agencyId}/{formId}/{userId}/{fieldName}/',
        // })
        this.optionData.aliOSSUpload = aliUploader;
        this.optionData.aliOSSUploadConfig = {
          //OK
          formId: "formId",
          agencyId: this.formAgencyId,
          userId: sessionStorageUser.userId || "userId",
          uploadPath: "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/",
        };

        this.optionData.userSelectHandler = this.showUserSelect;
        this.optionData.formCreateUser = sessionStorageUser;
        this.optionData.isUserAdmin = getSessionStorageObj("users").type > 1;
        this.optionData.fileDelete = this.fileDelete;
      },
      //填报窗口 数据选择组件弹出选择数据
      showDataSelect(fillSelectOption, callback) {
        // callback:选择完成后的回调
        this.$refs.fillSelector.show(fillSelectOption, callback);
      },
      async showDataView(fillSelectOption, fillData, callback) {
        // 加载表单配置数据
        const res = await httpGetFormFieldV2(fillSelectOption.formId);

        // 同时加载，提升加载速度
        const loadField = new Promise((resolve, reject) => {
          httpGetFormFieldV2(fillSelectOption.formId)
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
        const loadData = new Promise((resolve, reject) => {
          fillDetail(fillData.fillId, fillSelectOption.formId)
            .then((res) => {
              resolve(res.data.fillJson);
            })
            .catch((err) => {
              reject(err);
            });
        });

        Promise.all([loadField, loadData]).then((values) => {
          this.$refs.dlgvFormViewer.showView({
            fillId: fillData.fillId,
            formId: fillSelectOption.formId,
            formJson: values[0],
            formData: values[1],
            showHiddenWidgets:  this.cur != 3,
            showFields: fillSelectOption.fields
          });
        });
      },

      showPasswordPrompt() {
        this.$prompt("请输入查看密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
          inputValidator: (value) => {
            if (!value) {
              return false;
            }
          },
          inputErrorMessage: "请输入正确的查看密码",
        }).then(({ value }) => {
          dataShareCheckPassword(this.shareId, value)
            .then((data) => {
              if (data.code === 200) {
                if (data.data) {
                  this.loadDataShare();
                } else {
                  throw "密码输入错误";
                }
              } else {
                throw "密码检查出现错误";
              }
            })
            .catch((e) => {
              this.$message({
                type: "warning",
                message: e,
              });
            });
        });
      },
      loadDataShare() {
        getDataShare(this.shareId).then((res) => {
          this.shareConfig = res.data;

          this.gridOptions.pagerConfig.currentPage = 1;
          this.gridOptions.data = [];
          this.dataQueryParams = {
            queryStatus: "",
            payStatus: "",
            fillStartTime: "",
            fillEndTime: "",
            formFieldId: "",
            fillValue: "",
          };

          this.formId = this.shareConfig.formId;
          Promise.all([
            this.getFormDetail(),
            this.getFormJson(),
            this.getFormData(),
          ]).then(([formDetail, formJson]) => {
            this.formDetail = formDetail;
            let fieldList = [];
            let widgetList = [];

            //过滤出允许显示的字段
            this.shareConfig.headers.forEach((item) => {
              let index = formJson.fieldList.findIndex((x) => x.name == item);
              if (index >= 0) fieldList.push(formJson.fieldList[index]);

              index = formJson.widgetList.findIndex(
                (x) => x.options.name == item
              );
              if (index >= 0) widgetList.push(formJson.widgetList[index]);
            });

            Object.keys(this.shareConfig.filterCriteria).forEach((key) => {
              let fieldIndex = fieldList.findIndex((x) => x.name == key);
              if (fieldIndex >= 0) {
                delete fieldList[fieldIndex].filters;
                delete fieldList[fieldIndex].filterType;
                delete fieldList[fieldIndex].filterRender;
              }
            });
            console.log("fieldList", fieldList);

            this.formJson = {
              formConfig: formJson.formConfig,
              widgetList: widgetList,
              fieldList: fieldList,
            };
            this.$nextTick(() => {
              // _this.$refs.vformRender.setFormJson(_this.formJson)
            });

            this.renderTableColumns(this.gridOptions, false);
            this.renderAdminTableHeaderColumns(this.gridOptions);
          });
          // this.getDataList()
        });
      },
      // 渲染表头
      renderTableColumns(refGrid, hideHiddenField) {
        const _this = this;
        refGrid.columns = [];
        gridConfig
          .renderHeaderColumns(
            _this.formJson.fieldList,
            {
              formDetail: _this.formDetail,
              dataFillStatus: false,
            },
            { hideHiddenField: hideHiddenField }
          )
          .forEach((c) => {
            refGrid.columns.push(c);
          });
      },
      // 渲染管理表头
      renderAdminTableHeaderColumns(refGrid) {
        const _this = this;

        gridConfig
          .renderAdminHeaderColumns(_this.formJson.fieldList, {
            formDetail: _this.formDetail,
            dataFillStatus: false,
          })
          .forEach((c) => {
            refGrid.columns.push(c);
          });
      },
      getFormDetail() {
        const _this = this;
        return new Promise((resolve) => {
          // 获取表单其他配置
          formDetail(_this.formId).then((response, request) => {
            resolve(response.data);
          });
        });
      },
      getFormJson() {
        const _this = this;
        return new Promise((resolve) => {
          // 获取表单其他配置
          httpGetFormFieldV2(_this.formId).then((response, request) => {
            resolve(response.data);
          });
        });
      },
      getFormData() {
        const _this = this;
        let orders = _this.shareConfig.orders;

        if (_this.dataQueryParams.sortData) {
          _this.dataQueryParams.sortData.forEach((item) => {
            let index = orders.findIndex((x) => x.field == item.field);
            if (index >= 0) {
              orders.splice(index, 1);
            }
          });
        }

        const queryParams = {
          pageNum: _this.gridOptions.pagerConfig.currentPage
            ? _this.gridOptions.pagerConfig.currentPage
            : 1,
          pageSize: _this.gridOptions.pagerConfig.pageSize
            ? _this.gridOptions.pagerConfig.pageSize
            : 10,
          criteria: {
            ..._this.shareConfig.filterCriteria,
            ..._this.dataQueryParams.filterData,
          },
          orders: [...orders, ...(_this.dataQueryParams.sortData || [])],
          formFieldId: _this.dataQueryParams.formFieldId,
          fillValue: _this.dataQueryParams.fillValue,
          queryStatus: _this.dataQueryParams.queryStatus,
          payStatus: _this.dataQueryParams.payStatus,
          fillStartTime: _this.dataQueryParams.fillStartTime,
          fillEndTime: _this.dataQueryParams.fillEndTime,
          searchText: _this.dataQueryParams.fillValue, //全局搜索
        };
        return new Promise((resolve) => {
          fillDataDetail(_this.formId, queryParams).then((response) => {
            for (let i = 0; i < response.rows.length; i++) {
              const item = response.rows[i];
              const obj = { ...item.fillJson };

              // 表单开通支付
              obj["payStatus"] = item.payStatus;
              switch (item.payStatus) {
                case 1:
                  item.payStatusTxt = "无需支付";
                  break;
                case 2:
                  item.payStatusTxt = "待支付";
                  break;
                case 3:
                  item.payStatusTxt = "已支付";
                  break;
                case 4:
                  item.payStatusTxt = "确认支付";
                  break;
              }
              obj["payStatusTxt"] = item.payStatusTxt;
              obj["orderPriceTxt"] = item.orderPrice / 100 + "元";
              obj["payTime"] = !_this.isBlank(item.payTime) ? item.payTime : "--";
              let statusTxt = "";
              switch (item.status) {
                case 1:
                  statusTxt = "无需审核";
                  break;
                case 2:
                  if (!_this.isBlank(item.auditAuth1AgencyUserList)) {
                    let auditAuth1AgencyUser = "";
                    for (
                      let j = 0;
                      j < item.auditAuth1AgencyUserList.length;
                      j++
                    ) {
                      auditAuth1AgencyUser +=
                        item.auditAuth1AgencyUserList[j].name + ",";
                    }
                    // 去掉最后一个逗号
                    if (auditAuth1AgencyUser.length > 0) {
                      auditAuth1AgencyUser = auditAuth1AgencyUser.substr(
                        0,
                        auditAuth1AgencyUser.length - 1
                      );
                    }
                    statusTxt = "待" + auditAuth1AgencyUser + "审核"; // '待一级审核'
                  } else {
                    statusTxt = "--";
                  }
                  break;
                case 3:
                  statusTxt = "已通过"; // 一级审核通过
                  break;
                case 4:
                  statusTxt = "已驳回"; // 一级审核不通过
                  break;
                case 5:
                  statusTxt = "返回修改"; // 一级审核返回修改
                  break;
                case 6:
                  if (!_this.isBlank(item.auditAuth2AgencyUserList)) {
                    let auditAuth2AgencyUser = "";
                    for (
                      let k = 0;
                      k < item.auditAuth2AgencyUserList.length;
                      k++
                    ) {
                      auditAuth2AgencyUser +=
                        item.auditAuth2AgencyUserList[k].name + ",";
                    }
                    // 去掉最后一个逗号
                    if (auditAuth2AgencyUser.length > 0) {
                      auditAuth2AgencyUser = auditAuth2AgencyUser.substr(
                        0,
                        auditAuth2AgencyUser.length - 1
                      );
                    }
                    statusTxt = "待" + auditAuth2AgencyUser + "审核"; // '待二级审核'
                  } else {
                    statusTxt = "--";
                  }
                  break;
                case 7:
                  statusTxt = "已通过"; // 二级审核通过
                  break;
                case 8:
                  statusTxt = "已驳回"; // 二级审核不通过
                  break;
                case 9:
                  statusTxt = "返回修改"; // 二级审核返回修改
                  break;
              }

              obj["auditAuth1Remarks"] = item.auditAuth1Remarks;
              obj["auditAuth2Remarks"] = item.auditAuth2Remarks;
              obj["auditAuth3Remarks"] = item.auditAuth3Remarks;
              obj["auditAuth1Sign"] = item.auditAuth1Sign;
              obj["auditAuth2Sign"] = item.auditAuth2Sign;
              obj["auditAuth3Sign"] = item.auditAuth3Sign;
              
              obj["auditAuth1AgencyUserList"] = item.auditAuth1AgencyUserList;
              obj["auditAuth2AgencyUserList"] = item.auditAuth2AgencyUserList;
              obj["auditAuth3AgencyUserList"] = item.auditAuth3AgencyUserList;
              obj["auditAuth1Operator"] = item.auditAuth1Operator;
              obj["auditAuth2Operator"] = item.auditAuth2Operator;
              obj["auditAuth3Operator"] = item.auditAuth3Operator;
              obj["auditAuth1Time"] = item.auditAuth1Time;
              obj["auditAuth2Time"] = item.auditAuth2Time;
              obj["auditAuth3Time"] = item.auditAuth3Time;
              obj["auditAuthSign"] = item.auditAuthSign;
              obj["auditAuthRemarks"] = item.auditAuthRemarks;
              obj["auditAuthTime"] = item.auditAuthTime;
              
              obj["status"] = item.status;
              obj["statusTxt"] = statusTxt;
              obj["reviewRemark"] = !_this.isBlank(item.auditAuth2Remarks)
                ? item.auditAuth2Remarks
                : !_this.isBlank(item.auditAuth1Remarks)
                ? item.auditAuth1Remarks
                : "--";
              obj["userId"] = item.userId;
              obj["fillUserNumber"] = !this.isBlank(item.fillUserNumber)
                ? item.fillUserNumber
                : "-";
              obj["fillUserName"] = item.fillUserName;
              obj["fillUserAvatarUrl"] = item.fillUserAvatarUrl;
              obj["createTime"] = item.createTime;
              obj["agencyDeptList"] = item.agencyDeptList;

              obj["reviewSign"] = item.reviewSign;
              if (_this.dataFillStatus) {
                switch (item.fillStatus) {
                  case 1:
                    item.fillStatusTxt = "个人填报";
                    break;
                  case 2:
                    item.fillStatusTxt = "导入待确认";
                    break;
                  case 3:
                    item.fillStatusTxt = "导入已确认";
                    break;
                  case 4:
                    item.fillStatusTxt = "导入无需确认";
                    break;
                }
                obj["fillStatusTxt"] = item.fillStatusTxt;
                obj["fillStatus"] = item.fillStatus;
              }
              if (!_this.isBlank(item.agencyDeptLevel1)) {
                let agencyDeptLevel1 = "";
                for (let j = 0; j < item.agencyDeptLevel1.length; j++) {
                  agencyDeptLevel1 += item.agencyDeptLevel1[j].deptName + ",";
                }
                // 去掉最后一个逗号
                if (agencyDeptLevel1.length > 0) {
                  agencyDeptLevel1 = agencyDeptLevel1.substr(
                    0,
                    agencyDeptLevel1.length - 1
                  );
                }
                obj["agencyDeptLevel1"] = agencyDeptLevel1;
              } else {
                obj["agencyDeptLevel1"] = "--";
              }
              if (!_this.isBlank(item.agencyDeptLevel2)) {
                let agencyDeptLevel2 = "";
                for (let j = 0; j < item.agencyDeptLevel2.length; j++) {
                  agencyDeptLevel2 += item.agencyDeptLevel2[j].deptName + ",";
                }
                // 去掉最后一个逗号
                if (agencyDeptLevel2.length > 0) {
                  agencyDeptLevel2 = agencyDeptLevel2.substr(
                    0,
                    agencyDeptLevel2.length - 1
                  );
                }
                obj["agencyDeptLevel2"] = agencyDeptLevel2;
              } else {
                obj["agencyDeptLevel2"] = "--";
              }
              obj["fillId"] = item.fillId;
              _this.gridOptions.data.push(obj);
              _this.gridOptions.pagerConfig.total = response.total;
            }
            _this.gridOptions.loading = false;
            if (_this.isBlank(_this.gridOptions.data)) {
              _this.dataEmptyText = "暂无数据";
            }

            // this.$nextTick(() => {
            resolve();
            // })
          });
        });
      },
      handleDataPageChange({ currentPage, pageSize }) {
        this.gridOptions.pagerConfig.currentPage = currentPage;
        this.gridOptions.pagerConfig.pageSize = pageSize;
        this.gridOptions.data = [];
        this.getDataList(() => {
          this.restoreFillter(
            this.$refs.gridTable,
            this.dataQueryParams.filterData,
            this.dataQueryParams.sortData
          );
        });
      },
      // 填报页面---填报上传图片预览
      onHandlePreviewImg(file) {},
      // 管理列表，点击查询事件
      gridFilterChanged(filterObjects) {
        this.gridOptions.pagerConfig.currentPage = 1;
        this.gridOptions.data = [];
        let filterData = {};

        filterObjects.forEach((x) => {
          if (x.sysField) {
            if (x.filterType == "createTime") {
              let showValue = x.showValue.split("~");
              this.dataQueryParams.fillStartTime = showValue[0] + " 00:00:00";
              this.dataQueryParams.fillEndTime = showValue[1] + " 23:59:59";
            } else if (x.filterType == "sysStatus") {
              this.dataQueryParams.queryStatus = x.value;
            } else if (x.filterType == "payStatus") {
              this.dataQueryParams.payStatus = x.value;
            }
          } else {
            filterData[x.field] = x.value;
          }
        });

        if (filterObjects.length > 0) {
          this.dataQueryParams.filterData = filterData;
          this.dataQueryParams.filterObjects = filterObjects;
        } else {
          this.dataQueryParams.filterData = undefined;
          this.dataQueryParams.fillStartTime = undefined;
          this.dataQueryParams.fillEndTime = undefined;
          this.dataQueryParams.queryStatus = undefined;
          this.dataQueryParams.payStatus = undefined;
        }

        this.getFormData().then(() => {
          let sortData = this.dataQueryParams.sortData;

          console.log(filterData);
          this.restoreFillter(this.$refs.gridTable, filterData, sortData);
        });

        this.noticeClickNum++;
      },
      gridSortChanged(sortObjects) {
        this.gridOptions.pagerConfig.currentPage = 1;
        this.gridOptions.data = [];

        this.dataQueryParams.sortData = sortObjects;
        this.getFormData().then(() => {
          // let filterData = this.dataQueryParams.filterData;
          let sortData = this.dataQueryParams.sortData;
          this.restoreFillter(
            this.$refs.gridTable,
            this.dataQueryParams.filterObjects,
            sortData
          );
        });
      },

      // 数据查看列表，点击查询事件
      onHandleQueryDataClick() {
        // this.gridOptions.columns = []
        this.gridOptions.data = [];
        this.gridOptions.pagerConfig.currentPage = 1;
        this.getFormData();
      },
      // 数据查看列表，点击重置事件
      onHandleResetDataClick() {
        this.dataQueryParams.queryStatus = "";
        this.dataQueryParams.payStatus = "";
        this.dataQueryParams.fillStartTime = "";
        this.dataQueryParams.fillEndTime = "";
        this.dataQueryParams.formFieldId = "";
        this.dataQueryParams.fillValue = "";
        this.gridOptions.pagerConfig.currentPage = 1;
        // this.gridOptions.columns = []
        this.gridOptions.data = [];
        //clearFilter 触发页面刷新，不需要再手动加载数据了
        this.$refs.gridTable.clearFilter();
        this.$refs.gridTable.clearSort(true);
        this.$refs.gridTable.clearSelected();
        if (this.$refs.xGrid) {
          this.$refs.xGrid.clearFilter();
          this.$refs.xGrid.clearSelected();
          this.$refs.xGrid.clearCheckboxRow();
        }
        this.selectedDataRowKeys = [];
        //clearFilter 触发页面刷新，不需要再手动加载数据了
        // this.getDataList()
      },
      restoreFillter(el, filterDataArray, sortData) {
        if (filterDataArray && Array.isArray(filterDataArray)) {
          filterDataArray.forEach((f) => {
            el.setFilter(f.field, f.value);
          });
        } else if (filterDataArray) {
          Object.keys(filterDataArray).forEach((f) => {
            el.setFilter(f, filterDataArray[f]);
          });
        }

        if (sortData) {
          sortData.forEach((field) => {
            el.setSort(field);
          });
        }
      },
      // 编辑填报列表某一项
      onEditFillClick(fillId, editType, listType) {
        if (!fillId) {
          fillId = this.selectedDataRowKeys[0].fillId;
        }
        fillDetail(fillId, this.formId).then((response) => {
          if (response.code == 200) {
            this.fillId = response.data.fillId;
            const data = response.data.fillJson;

            //如果是管理列表，只读字段打开可编辑
            const isEnableReadonly = listType == "MANAGE";
            // 个人填报时不显示隐藏按钮
            this.$refs.dlgvFormViewer.showView({
              fillId,
              formId: this.formId,
              formJson: deepClone(this.formJson),
              formData: data,
              showHiddenWidgets: this.cur != 3,
              showFields: null,
              enableReadonly: isEnableReadonly
            });
            // }

            // //如果是个人填报列表，已支付状态的支付字段不能修改
            // if(this.cur==3){
            //   setTimeout(()=>{
            //     //判断表单是否开启了支付，支付状态为已支付，表单是根据字段支付，三个条件为true，隐藏支付字段。
            //     if(this.formDetail.pay&&response.data.payStatus>2&&this.formPayInfo){
            //       console.log("setDisabled")
            //       this.$refs.dlgvFormViewer.hideWidgets([this.formPayInfo.formFieldId])
            //     }
            //   },200)
            // }
          }
        });
      },
      fileDelete(file) {
        deleleteFile(file.fileId).then((res) => {});
        if (file.thumbFileId) {
          deleleteFile(file.thumbFileId).then((res) => {});
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "~@/assets/icons/iconfont/iconfont.css";

  .statics-wrapper {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    box-sizing: inherit;
    width: 100%;
    margin-bottom: 20px;

    .statics-title {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Arial, sans-serif;
      box-sizing: inherit;
      color: #1f2d3d;
      font-weight: 500;
      font-size: 16px;
      padding-bottom: 20px;
    }

    .statics-search {
      line-height: 50px;
      height: 50px;
    }

    .count-chart-container {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Arial, sans-serif;
      display: -webkit-flex;
      width: 100%;
      border: 1px solid #e5e5e5;
      padding: 20px 0;
      box-sizing: border-box;

      .count-chart-item {
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
          Microsoft YaHei, Arial, sans-serif;
        box-sizing: inherit;
        text-align: center;
        font-weight: 400;
        display: -webkit-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        user-select: none;

        .current-amount {
          line-height: 1.15;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
            Microsoft YaHei, Arial, sans-serif;
          text-align: center;
          font-weight: 400;
          -webkit-box-direction: normal;
          user-select: none;
          box-sizing: inherit;
          font-size: 30px;
          color: #262a3e;
          margin-bottom: 10px;
        }
        .sum {
          line-height: 1.15;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
            Microsoft YaHei, Arial, sans-serif;
          text-align: center;
          font-weight: 400;
          -webkit-box-direction: normal;
          user-select: none;
          box-sizing: inherit;
          padding-left: 2px;
          color: #b0b0b9;
          font-size: 14px;
        }
      }
    }
  }
</style>
